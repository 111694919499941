import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

import { BACKEND_URL } from './utils/constants';

import initFontAwesome from './utils/initFontAwesome';
import Loading from './components/Loading';

import Home from './views/Home';

import NewMember from './views/members/NewMember';
import ExperienceStats from './views/stats/ExperienceStats';

import EditJackpot from './views/EditJackpot';
import NewJackpot from './views/NewJackpot';
import JackpotList from './views/JackpotList';
import EditPrize from './views/EditPrize';
import NewPrize from './views/NewPrize';
import PrizeList from './views/PrizeList';
import NewTeam from './views/NewTeam';
import TeamList from './views/TeamList';
import PlayerList from './views/PlayerList';
import BoostRequestList from './views/BoostRequestList';
import ExperienceMan from './views/ExperienceMan';
import InviteLeader from './views/members/InviteLeader';
import InvitePlayer from './views/members/InvitePlayer';
import UnprotectedRoute from './views/UnprotectedRoute';
import ProtectedRouteLeader from './views/ProtectedRouteLeader';
import ProtectedRouteSupreme from './views/ProtectedRouteSupreme';
import LandingPage from './views/LandingPage';
import ExperienceDetail from './views/experience/ExperienceDetail';
import NewExperience from './views/experience/NewExperience';
import Client from './client/Client';
import Questionary from './client/questionary/Questionary';
import PrizeCatalog from './client/prizes/PrizeCatalog';
import Chest from './client/chest/Chest';
import { applyHeaders, fetchData } from './api/axiosClient';

import './App.css';
import PrizeRedemption from './client/prizes/PrizeRedemption';
import GamePlayerProfile from './client/GamePlayerProfile';
import StatsDetails from './views/stats/components/StatsDetails';
import Admin from './views/Admin';
import CompanyList from './views/company/CompanyList';
import ProtectedRouteTrooper from './views/ProtectedRouteTrooper';
import CompanyDetail from './views/company/CompanyDetail';
import InviteSupremeLeader from './views/members/InviteSupremeLeader';
import NoMembership from './views/NoMembership';
import ProtectedRoutePlayer from './views/ProtectedRoutePlayer';
import { setActor, setInventory, setPlayer, setTeam, useAppAuthenticated } from './storage/storage';
import JackpotDetail from './client/prizes/JackpotDetail';
import Logout from './views/Logout';
import Bonus from './client/Bonus';

initFontAwesome();

const App = () => {
  const { isLoading, error, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const isAppAuthenticated = useAppAuthenticated();
  const dispatch = useDispatch();

  const [redirect, setRedirect] = useState({
    to: null,
    enabled: false,
  });

  useEffect(() => {
    localStorage.clear();
    const resolveRedirect = () => {
      const isNewMember = window.location.pathname.indexOf('/membership') === 0;
      const to = isAppAuthenticated
        ? 'landing'
        : isNewMember
          ? `${window.location.pathname}${window.location.search}`
          : '/no-membership';
      setRedirect({
        to: to,
        enabled: true,
      });
    };
    const fetchInventory = async (playerId) => {
      try {
        const getUrl = `${BACKEND_URL}/inventory/?playerId=${playerId}`;
        const inventory = await fetchData(getUrl, getAccessTokenSilently);
        setInventory(inventory, dispatch);
      } catch (error) {
        console.error(error);
      }
    };

    const getActor = async () => {
      if (!isAuthenticated || isAppAuthenticated || redirect.enabled) return;
      const headers = await applyHeaders(getAccessTokenSilently);
      try {
        const result = await axios.get(`${BACKEND_URL}/player`, headers).then((response) => response.data);

        if (!result) {
          resolveRedirect();
          return;
        }
        setActor(result.actor, dispatch);
        if (result.players.length) {
          setPlayer(result.players[0].player, dispatch);
          setTeam(result.players[0].team, dispatch);
          await fetchInventory(result.players[0].player.player_id);
        }
      } catch (error) {
        if (error.response.status === 404 || error.response.status === 401) {
          resolveRedirect();
        }
      }
    };

    getActor();
  }, [user, isAuthenticated, isAppAuthenticated, redirect, getAccessTokenSilently, dispatch]);

  if (error) return <div>Oops... {error.message}</div>;

  if (isLoading) return <Loading />;

  return (
    <div id="app" className="App d-flex flex-column h-100">
      <BrowserRouter>
        {redirect.enabled && !isAppAuthenticated && <Navigate to={redirect.to} />}
        <Routes>
          <Route exact path="/no-membership" element={<NoMembership />} />
          <Route path="/logout" element={<Logout />} />
          <Route element={<ProtectedRoutePlayer />}>
            <Route path="chest" element={<Chest />} />
            <Route path="/client" element={<Client />} />
            <Route path="/profile" element={<GamePlayerProfile />} />
            <Route path="/prize-catalog" element={<PrizeCatalog />} />
            <Route path="/prize-redemption/:id" element={<PrizeRedemption />} />
            <Route path="/jackpot-detail" element={<JackpotDetail />} />
            <Route path="/questionary" element={<Questionary />} />
            <Route path="/bonus" element={<Bonus />} />
          </Route>
          <Route path="/" element={<Admin />}>
            <Route path="landing" element={<LandingPage />} />
            <Route element={<UnprotectedRoute />}>
              <Route index element={<Home />} />
              <Route exact path="/membership" element={<NewMember />} />
            </Route>
            <Route element={<ProtectedRouteLeader />}>
              <Route exact path="/team/new" element={<NewTeam />} />
              <Route exact path="/team" element={<TeamList />} />
              <Route exact path="/team/:id" element={<PlayerList />} />
              <Route exact path="/team/:id/invite" element={<InvitePlayer />} />
            </Route>
            <Route element={<ProtectedRouteSupreme />}>
              <Route exact path="/experience/new" element={<NewExperience />} />
              <Route exact path="/experience/:id/stats" element={<ExperienceStats />} />
              <Route exact path="/experience/:id/stats/:statsName" element={<StatsDetails />} />
              <Route exact path="/experience/:id" element={<ExperienceDetail />} />
              <Route exact path="/experience" element={<ExperienceMan />} />
              <Route path="/jackpot" element={<JackpotList />} />
              <Route path="/jackpot/new" element={<NewJackpot />} />
              <Route path="/jackpot/:id/edit" element={<EditJackpot />} />
              <Route path="/prize" element={<PrizeList />} />
              <Route path="/prize/new" element={<NewPrize />} />
              <Route path="/prize/:id/edit" element={<EditPrize />} />
              <Route path="/invite/leader" element={<InviteLeader />} />
              <Route path="/team/boost-requests" element={<BoostRequestList />} />
            </Route>
            <Route element={<ProtectedRouteTrooper />}>
              <Route path="/companies" element={<CompanyList />} />
              <Route path="/companies/new" element={<CompanyDetail />} />
              <Route path="/companies/:id" element={<CompanyDetail />} />
              <Route path="/companies/:id/new-members" element={<InviteSupremeLeader />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
