import { useAuth0 } from '@auth0/auth0-react';
import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

const NoMembership = () => {
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth0();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);
  const logoutWithRedirect = () => {
    if (!isAuthenticated) return navigate('/');
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <Fragment>
      <div className="text-center Init my-5">
        <p className="lead">{t('membership.no')}</p>
        <Button onClick={logoutWithRedirect} color="primary">
          {t('buttons.back')}
        </Button>
      </div>
    </Fragment>
  );
};

export default NoMembership;
