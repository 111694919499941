import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import galiTicket from '../../assets/img/icons/galiticket.svg';

import JigsawBuilder from '../JigsawBuilder';
import orbitPieces from '../../assets/img/chest/orbit-pieces.svg';
import { useJackpot, usePrizes } from '../../storage/storage';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { getCatalogueLevelLabel } from '../../utils/constants';

const Carousel = ({ handleCollectRewards, rewards }) => {
  const { t, i18n } = useTranslation();
  const jackpot = useJackpot();
  const prizes = usePrizes();

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const mapToReward = (reward) => {
    const prize = prizes.find((prize) => prize.prize_id === reward.prize_id);
    return {
      title: `${prize.name} (x${reward.pieces.length})`,
      subtitle: t(getCatalogueLevelLabel(prize.level)),
      img: prize.url,
      totalPieces: prize.total_pieces,
      piecesCollected: prize.uniquePieces,
      pieceCount: prize.pieceCount,
    };
  };

  const enrichedRewards = rewards.data.map((reward) => mapToReward(reward));
  enrichedRewards.push({
    title: jackpot.name,
    subtitle: t('menu.lottery'),
    img: jackpot.url,
    piecesCollected: `x ${rewards.tickets}`,
  });

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? enrichedRewards.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === enrichedRewards.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="carousel-container">
      <div className="carousel-item-title">
        <h1>{enrichedRewards[currentIndex].title}</h1>
        <p>{enrichedRewards[currentIndex].subtitle}</p>
      </div>

      <div className="carousel-item-image">
        <div className="carousel-control-container">
          <button style={{ visibility: currentIndex === 0 ? 'hidden' : 'visible' }} onClick={handlePrev}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <div className="img-piece-container">
            <img src={orbitPieces} className="orbit-piece" alt="orbitPieces" />
            <img src={enrichedRewards[currentIndex].img} className="img-piece" alt="reward" />
          </div>
          <button
            style={{
              visibility: currentIndex === enrichedRewards.length - 1 ? 'hidden' : 'visible',
            }}
            onClick={handleNext}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
        <div className="carousel-item-indicators">
          {enrichedRewards.map((item, index) => (
            <span key={index} className={`dot ${index === currentIndex ? 'active' : ''}`}></span>
          ))}
        </div>
      </div>
      {!!enrichedRewards[currentIndex].pieceCount ? (
        <div className="carousel-item-pieces">
          <div className="jigsaw-parts">
            <JigsawBuilder isLocked={false} pieces={enrichedRewards[currentIndex].pieceCount} asIcon={true} />
          </div>
          <h1>
            {enrichedRewards[currentIndex].piecesCollected}/{enrichedRewards[currentIndex].totalPieces}
          </h1>
        </div>
      ) : (
        <div className="carousel-item-tickets">
          <h1>GaliTickets</h1>
          <div className="jigsaw-parts">
            <img src={galiTicket} alt="GaliTickets" />
            <h1>{enrichedRewards[currentIndex].piecesCollected}</h1>
          </div>
        </div>
      )}
      <button className="gali-button primary" onClick={handleCollectRewards}>
        + {t('game.chest.carousel.collect')}
      </button>
    </div>
  );
};

export default Carousel;
