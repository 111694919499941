import React, { useEffect } from 'react';
import { Button, ButtonGroup, Table } from 'reactstrap';

import { useFetchJackpotList } from '../../../api/JackpotAPI';
import { useActor } from '../../../storage/storage';
import '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/Loading';

const JackpotsTable = ({ jackpot, setJackpot }) => {
  const actor = useActor();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const { data: jackpotsList, loading, error } = useFetchJackpotList(actor.company_id);

  return (
    <Table hover responsive size="sm" striped className="text-center">
      <thead>
        <tr key="header">
          <th>{t('tables.name')}</th>
          <th>{t('tables.select')}</th>
        </tr>
      </thead>
      <tbody>
        {jackpotsList &&
          jackpotsList.map((aJackpot) => (
            <tr key={aJackpot.jackpot_id}>
              <td>{aJackpot.name}</td>
              <td>
                <ButtonGroup>
                  <Button
                    color="primary"
                    outline
                    name={aJackpot.jackpot_id}
                    onClick={() => setJackpot(aJackpot)}
                    active={jackpot && jackpot.jackpot_id === aJackpot.jackpot_id}
                  />
                </ButtonGroup>
              </td>
            </tr>
          ))}
        {!jackpotsList && loading && (
          <tr>
            <td colSpan={4}>
              <Loading />
            </td>
          </tr>
        )}
        {!jackpotsList && error && (
          <tr>
            <td colSpan={4}>{t('tables.error')}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default JackpotsTable;
