import { useEffect } from 'react';
import { Card, CardBody, CardImg, CardImgOverlay, CardText } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import galiTicket from '../../assets/img/icons/galiticket.svg';

const JackpotCard = ({ jackpot }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  return (
    <Card color="dark" inverse onClick={() => navigate(`/jackpot-detail`)}>
      <CardImg alt="Jackpot Image" src={jackpot.url} top width="100%" />
      <CardImgOverlay>
        <CardBody>
          <CardText style={{ position: 'absolute', bottom: '0px', left: '10px' }}>
            <img src={galiTicket} alt="GaliTickets" style={{ width: '32px' }} />
            <div>{t('game.catalogue.jackpotMessage')}</div>
          </CardText>
        </CardBody>
      </CardImgOverlay>
    </Card>
  );
};

export default JackpotCard;
