import { useEffect, useState, useCallback } from 'react';
import { fetchData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { setJackpot, useActor } from '../storage/storage';
import { useDispatch } from 'react-redux';

const useFetchJackpotList = () => {
  const dispatch = useDispatch();
  const actor = useActor();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = `${BACKEND_URL}/company/${actor.company_id}/jackpot`;

  useEffect(() => {
    const fetchJackpotsList = async () => {
      try {
        const { data } = await fetchData(url, getAccessTokenSilently);
        setJackpot(data[0], dispatch);
      } catch (error) {
        if (error.response.status === 404) return;
        console.error('There was an error fetching the jackpots list!', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchJackpotsList();
  }, [url]);

  return { loading, error };
};

const useFetchJackpotById = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const getJackpotById = useCallback(
    async (jackpotId) => {
      const url = `${BACKEND_URL}/jackpot/${jackpotId}`;
      setLoading(true);
      try {
        return await fetchData(url, getAccessTokenSilently);
      } catch (error) {
        console.error('There was an error getting the jackpot by Id!', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { loading, error, getJackpotById };
};

export { useFetchJackpotList, useFetchJackpotById };
