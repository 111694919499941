import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Alert, Label, Card, CardBody, CardHeader, CardImg, Col, Input } from 'reactstrap';
import Loading from '../../components/Loading';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { validateImageFile } from '../../utils/componentUtil';
import axios from 'axios';
import { BACKEND_URL } from '../../utils/constants';
import { applyHeaders } from '../../api/axiosClient';
import ReturnButton from '../../components/ReturnButton';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const CompanyDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [company, setCompany] = useState({
    name: null,
    max_players: 5,
    max_leaders: 3,
  });
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  const [state, setState] = useState({
    redirect: false,
    error: null,
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
    const getCompany = async () => {
      if (!id) return;
      const headers = await applyHeaders(getAccessTokenSilently);
      await axios
        .get(`${BACKEND_URL}/company/${id}`, headers)
        .then((response) => setCompany(response.data))
        .catch((e) => setFetchError(e.response.data.message));
    };
    getCompany();
  }, [getAccessTokenSilently, id, setFetchError]);

  const handleInput = async (event) => {
    setState({ ...state, error: null });
    setCompany({
      ...company,
      [event.target.name]: event.target.value,
    });
  };

  const handleImage = async (event) => {
    const file = event.target.files[0];
    const error = validateImageFile(file);
    if (error) {
      setImageFile(null);
      return setState({ ...state, error });
    }
    setImageFile(file);
    setState({ ...state, error: error });
  };

  const submit = async (event) => {
    event.preventDefault();
    setState({ ...state, error: null });

    if (!imageFile) {
      setState({
        ...state,
        error: 'Invalid Image',
      });
      return;
    }
    if (!company.name) {
      setState({
        ...state,
        error: 'Invalid name',
      });
      return;
    }
    const leaders = parseInt(company.max_leaders);
    const players = parseInt(company.max_players);
    if (!leaders || isNaN(leaders) || !players || isNaN(players) || players < leaders || leaders < 0 || players < 0) {
      setState({
        ...state,
        error: 'Invalid Players or Leaders',
      });
      return;
    }
    setLoading(true);

    const newCompany = new FormData();
    newCompany.append('name', company.name);
    newCompany.append('image', imageFile);
    newCompany.append('max_leaders', leaders);
    newCompany.append('max_players', players);

    const headers = await applyHeaders(getAccessTokenSilently, 'multipart/form-data');
    const url = `${BACKEND_URL}/company/${id || ''}`;
    const operation = id ? axios.patch(url, newCompany, headers) : axios.post(url, newCompany, headers);
    await operation
      .then(() => navigate('/companies'))
      .catch((e) =>
        setState({
          ...state,
          error: e.response.data.message,
        })
      )
      .finally(() => setLoading(false));
  };

  if (state.redirect) return <Navigate to="/companies" />;

  return (
    <>
      {state.error && <Alert color="danger">{state.error}</Alert>}
      {fetchError && <Alert color="danger">{fetchError}</Alert>}
      <h2 className="admin-text">Company</h2>
      <Form onSubmit={submit}>
        <FormGroup row>
          <Label className="admin-text" for="name" sm={2}>
            {t('form.name')}
          </Label>
          <Col sm={6}>
            <Input
              id="name"
              name="name"
              placeholder={t('form.name')}
              type="text"
              value={company && company.name}
              onChange={handleInput}
              valid={!!company.name}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label className="admin-text" for="file" sm={2}>
            {t('form.logo')}
          </Label>
          <Col sm={6}>
            <Card>
              <CardHeader>
                <Input id="file" name="file" type="file" accept="image/*" onChange={handleImage} valid={!!imageFile} />
              </CardHeader>
              <CardBody>
                {company && (
                  <CardImg
                    id="image"
                    alt="Logo"
                    src={imageFile ? URL.createObjectURL(imageFile) : company.url}
                    style={{
                      width: '24rem',
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label className="admin-text" for="max_leaders" sm={2}>
            {t('company.leaders')}
          </Label>
          <Col sm={6}>
            <Input
              id="max_leaders"
              name="max_leaders"
              placeholder={t('company.leaders')}
              type="number"
              value={company && company.max_leaders}
              min={0}
              defaultValue={3}
              onChange={handleInput}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label className="admin-text" for="max_players" sm={2}>
            {t('company.players')}
          </Label>
          <Col sm={6}>
            <Input
              id="max_players"
              name="max_players"
              placeholder={t('company.players')}
              type="number"
              value={company && company.max_players}
              onChange={handleInput}
              defaultValue={5}
              min={0}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <ReturnButton to={'/companies'} />
            <Button color="primary" type="submit" disabled={loading}>
              {t('buttons.ok')}
            </Button>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
};

export default withAuthenticationRequired(CompanyDetail, {
  onRedirecting: () => <Loading />,
});
