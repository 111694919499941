import React from 'react';
import Loading from '../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import InviteActor from './InviteActor';
import { useParams } from 'react-router-dom';

const InviteSupreme = () => {
  const { id } = useParams();

  return <InviteActor id={id} domain={'company'} navigateTo={'landing'} supreme={true} />;
};

export default withAuthenticationRequired(InviteSupreme, {
  onRedirecting: () => <Loading />,
});
