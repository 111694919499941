import React from 'react';
import Loading from '../../components/Loading';
import { useParams } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import InviteActor from './InviteActor';

const InvitePlayer = () => {
  const { id } = useParams();

  return <InviteActor id={id} domain={'team'} navigateTo={'team'} />;
};

export default withAuthenticationRequired(InvitePlayer, {
  onRedirecting: () => <Loading />,
});
