import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Form, FormGroup, Alert, Label, Col, ListGroup, ListGroupItem } from 'reactstrap';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Loading from '../../components/Loading';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { formatDateTime, isExpired } from '../../utils/date';
import axios from 'axios';
import ReturnButton from '../../components/ReturnButton';
import { BACKEND_URL } from '../../utils/constants';
import { applyHeaders } from '../../api/axiosClient';
import 'react-phone-number-input/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useActor, useAppAuthenticated } from '../../storage/storage';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const InviteActor = ({ id, domain, navigateTo, supreme }) => {
  const isAppAuthenticated = useAppAuthenticated();
  const actor = useActor();
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const [invitationDetails, setInvitationDetails] = useState();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [success, setSuccess] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [state, setState] = useState({
    redirect: false,
    error: null,
  });

  const [phoneNumberList, setPhoneNumberList] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const getInvitationDetails = async () => {
    const headers = await applyHeaders(getAccessTokenSilently);
    await axios
      .get(`${BACKEND_URL}/${domain}/${id}/invitation-details`, headers)
      .then((response) => setInvitationDetails(response.data))
      .catch((error) =>
        setState({
          ...state,
          error: error.response.data.message,
        })
      );
  };

  useEffect(() => {
    getInvitationDetails();
  }, []);

  const generateToken = async () => {
    const headers = await applyHeaders(getAccessTokenSilently);
    await axios
      .post(
        `${BACKEND_URL}/${domain}/${id}/invitation-token`,
        {
          actorId: actor.actor_id,
        },
        headers
      )
      .then((_) => getInvitationDetails())
      .catch((error) =>
        setState({
          ...state,
          error: error.response.data.message,
        })
      );
  };

  const submit = async (event) => {
    event.preventDefault();
    const headers = await applyHeaders(getAccessTokenSilently);
    await axios
      .post(`${BACKEND_URL}/${domain}/${id}/send-invitation`, { list: phoneNumberList, isSupreme: !!supreme }, headers)
      .then((_) => {
        setSuccess(true);
        setPhoneNumberList([]);
      })
      .catch((e) =>
        setState({
          ...state,
          error: e.response.data.message,
        })
      );
  };

  if (!isAppAuthenticated) return <Navigate to="/landing" />;

  const resetForm = () => {
    document.getElementById('inviteForm').reset();
  };

  const removePhoneNumber = (phoneNumber) => {
    let idx = phoneNumberList.indexOf(phoneNumber);
    if (idx <= -1) {
      return;
    }

    phoneNumberList.splice(idx, 1);
    setLimitReached(false);
    resetForm();

    setPhoneNumberList([...phoneNumberList]);
  };

  const reset = () => {
    setPhoneNumberList([]);
    setPhoneNumber(null);
    setLimitReached(false);
    setSuccess(false);
    resetForm();
  };

  const addPhoneNumber = (event) => {
    event.preventDefault();
    if (
      !phoneNumber ||
      phoneNumber.length === 0 ||
      phoneNumberList.indexOf(phoneNumber) > -1 ||
      !isValidPhoneNumber(phoneNumber)
    ) {
      return;
    }

    setPhoneNumberList([...phoneNumberList, phoneNumber]);

    setPhoneNumber(null);
    resetForm();
    if (phoneNumberList.length + 1 >= invitationDetails.remainingInvitations) {
      setLimitReached(true);
      return;
    }
  };

  if (state.redirect) return <Navigate to="/landing" />;

  if (isLoading) return <Loading />;

  return (
    <>
      {state.error && <Alert color="danger">{state.error}</Alert>}

      {success && <Alert color="success">{t('invitation.sent')}</Alert>}

      {invitationDetails &&
        invitationDetails.hasInvitationCapacity &&
        !isExpired(invitationDetails.expiration_token) && (
          <Alert>
            <h4 className="alert-heading">{t('invitation.ready.tokenGenerated')}</h4>
            <p>{t('invitation.ready.tokenGeneratedDet')}</p>
            <ul>
              <li>
                <b>{t('invitation.ready.expiration')}</b> {formatDateTime(invitationDetails.expiration_token)}
              </li>
              <li>
                <b>{t('invitation.ready.slots')}</b> {invitationDetails.remainingInvitations}
              </li>
            </ul>
            <hr />
            <p className="mb-0">{t('invitation.ready.footer')}</p>
          </Alert>
        )}

      {invitationDetails &&
        invitationDetails.hasInvitationCapacity &&
        (!invitationDetails.isTokenGenerated || isExpired(invitationDetails.expiration_token)) && (
          <>
            <Alert color="warning">
              <h4 className="alert-heading">{t('invitation.noToken.title')}</h4>
              <p>{t('invitation.noToken.body')}</p>
              <hr />
              <p className="mb-0">{t('invitation.noToken.footer')}</p>
            </Alert>
          </>
        )}

      {invitationDetails && !invitationDetails.hasInvitationCapacity && (
        <Alert color="danger">
          <h4 className="alert-heading">{t('invitation.full.title')}</h4>
          <p>
            {t('invitation.full.body1')} {invitationDetails.invitation_capacity} {t('invitation.full.body2')}
          </p>
          <hr />
          <p className="mb-0">{t('invitation.full.footer')}</p>
        </Alert>
      )}

      {invitationDetails &&
        invitationDetails.hasInvitationCapacity &&
        !isExpired(invitationDetails.expiration_token) && (
          <Form id="inviteForm" onSubmit={addPhoneNumber}>
            <FormGroup row>
              <Label className="admin-text" for="phoneNumber" sm={{ size: 1, offset: 2 }}>
                {t('invitation.phone')}
              </Label>
              <Col sm={4}>
                <PhoneInput
                  id="phoneNumber"
                  placeholder="+13053456789"
                  defaultCountry="US"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                />
              </Col>
              <Col sm={3}>
                <ButtonGroup className="d-flex">
                  <Button
                    id="addPhoneNumber"
                    color="primary"
                    block
                    type="submit"
                    disabled={limitReached || !phoneNumber}
                  >
                    {t('buttons.add')}
                  </Button>
                  <Button color="danger" block onClick={reset}>
                    {t('invitation.clear')}
                  </Button>
                </ButtonGroup>
              </Col>
            </FormGroup>
            {phoneNumberList && phoneNumberList.length > 0 && (
              <FormGroup row>
                <Label className="admin-text" sm={{ size: 1, offset: 2 }}>
                  {t('invitation.list')}
                </Label>
                <Col sm={7}>
                  <ListGroup numbered>
                    {phoneNumberList.map((item) => (
                      <ListGroupItem key={item} className="d-flex justify-content-between">
                        {item}{' '}
                        <Button
                          color="danger"
                          className="pull-right"
                          onClick={() => {
                            removePhoneNumber(item);
                          }}
                        >
                          <FontAwesomeIcon icon={faXmark} className="faWhite" />
                        </Button>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
              </FormGroup>
            )}
          </Form>
        )}
      <ButtonGroup>
        <ReturnButton to={`/${navigateTo}`} />
        {invitationDetails &&
          invitationDetails.hasInvitationCapacity &&
          (!invitationDetails.isTokenGenerated || isExpired(invitationDetails.expiration_token)) && (
            <Button color="primary" onClick={generateToken}>
              {t('invitation.getToken')}
            </Button>
          )}
        {invitationDetails &&
          invitationDetails.hasInvitationCapacity &&
          !isExpired(invitationDetails.expiration_token) && (
            <Button color="success" block onClick={submit} disabled={!phoneNumberList || phoneNumberList.length <= 0}>
              {t('invitation.send')}
            </Button>
          )}
      </ButtonGroup>
    </>
  );
};

export default InviteActor;
