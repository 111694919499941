import Loading from '../components/Loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  FormGroup,
  Alert,
  Row,
  Card,
  CardBody,
  CardHeader,
  ButtonGroup,
  CardImg,
} from 'reactstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import ReturnButton from '../components/ReturnButton';
import { BACKEND_URL } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { applyHeaders } from '../api/axiosClient';
import { useActor } from '../storage/storage';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

const JackpotList = () => {
  const { isLoading, error, getAccessTokenSilently } = useAuth0();
  const actor = useActor();
  const [jackpotList, setJackpotList] = useState();
  const [jackpot, setJackpot] = useState();
  const [state, setState] = useState({
    error: null,
  });
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const close = () => setModal(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    const getJackpotList = async () => {
      const companyId = actor.company_id;
      const headers = await applyHeaders(getAccessTokenSilently);
      await axios
        .get(`${BACKEND_URL}/company/${companyId}/jackpot`, headers)
        .then((response) => setJackpotList(response.data.data.length > 0 ? response.data.data : null))
        .catch((error) => {
          if (error.response.status === 404) {
            setJackpotList(null);
            return;
          }
          setState({
            ...state,
            error: error.response.data.message,
          });
        });
    };
    getJackpotList();
  }, [getAccessTokenSilently, actor, setState, state]);

  const load = async (id) => {
    const headers = await applyHeaders(getAccessTokenSilently);
    await axios
      .get(`${BACKEND_URL}/jackpot/${id}`, headers)
      .then((response) => setJackpot(response.data))
      .catch((error) => {
        if (error.response.status === 404) {
          setJackpot(null);
          setState({
            ...state,
            error: 'Error obteniendo información de premio',
          });
          return;
        }
        setState({
          ...state,
          error: error.response.data.message,
        });
      });
    setModal(!modal);
  };

  if (error) return <p>{error.message}</p>;

  if (isLoading) return <Loading />;

  return (
    <div>
      {state.error && <Alert color="danger">{state.error}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('menu.lottery')}</h3>
        </CardHeader>
        <CardBody>
          <ButtonGroup>
            <ReturnButton to={'/landing'} />
            <NavLink className="btn btn-primary" to="new">
              {t('buttons.add')}
            </NavLink>
          </ButtonGroup>
          <FormGroup row>{!jackpotList && <Alert color="light">{t('tables.empty')}</Alert>}</FormGroup>
          {jackpotList && (
            <Table hover responsive size="sm" striped className="text-center">
              <thead>
                <tr key="header">
                  <th>{t('tables.name')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {jackpotList.map((item) => (
                  <tr key={item.jackpot_id}>
                    <td>
                      <span className="text-dark badge">{item.name}</span>
                    </td>
                    <td>
                      <Button color="link" size="sm" className="view-icon" onClick={() => load(item.jackpot_id)}>
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                      <Button
                        color="link"
                        size="sm"
                        className="view-icon"
                        onClick={() => navigate(`${item.jackpot_id}/edit`)}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>

      {jackpot && (
        <Modal isOpen={modal} toggle={close}>
          <ModalHeader className="admin-text" toggle={close}>
            {jackpot && jackpot.name}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="text-center">
                <CardImg
                  alt={jackpot && jackpot.name}
                  src={jackpot && jackpot.url}
                  style={{
                    width: '22rem',
                  }}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className="text-center admin-text">
                <p>{jackpot && jackpot.description}</p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" size="sm" onClick={close}>
              {t('buttons.ok')}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default withAuthenticationRequired(JackpotList, {
  onRedirecting: () => <Loading />,
});
