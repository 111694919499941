import React from 'react';
import Loading from '../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import InviteActor from './InviteActor';
import { useActor } from '../../storage/storage';

const InviteLeader = () => {
  const actor = useActor();
  const id = actor.company_id;

  return <InviteActor id={id} domain={'company'} navigateTo={'landing'} />;
};

export default withAuthenticationRequired(InviteLeader, {
  onRedirecting: () => <Loading />,
});
