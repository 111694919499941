const validateImageFile = (file) => {
  if (!file) {
    return 'Imagen no seleccionada';
  }
  const validImagePattern = /^image\//;
  if (!validImagePattern.test(file.type)) {
    return 'El archivo debe ser una imagen (jpeg, png ...)';
  }
  const maxSize = 1024 * 1024;
  if (file.size > maxSize) {
    return 'El tamaño de la imagen no debe exceder 1MB';
  }
  return null;
};

const validateFile = (file) => {
  if (!file) {
    return 'Archivo no seleccionada';
  }
  if (file.type !== 'application/pdf') {
    return 'El archivo debe ser PDF';
  }
  const maxSize = 1024 * 1024 * 50;
  if (file.size > maxSize) {
    return 'El tamaño del archivo no debe exceder 50MB';
  }
  return null;
};

export { validateImageFile, validateFile };
