import { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Table, FormGroup, Alert, Button, ButtonGroup } from 'reactstrap';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { BACKEND_URL, LEVEL_COLORS, LEVEL_NAMES } from '../utils/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import Loading from '../components/Loading';
import ReturnButton from '../components/ReturnButton';
import { applyHeaders } from '../api/axiosClient';
import { useActor } from '../storage/storage';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

const TeamList = () => {
  const { isLoading, error, getAccessTokenSilently } = useAuth0();
  const actor = useActor();
  const [teamList, setTeamList] = useState();
  const [state, setState] = useState({
    error: null,
    redirect: false,
  });
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    const getTeamList = async () => {
      const headers = await applyHeaders(getAccessTokenSilently);
      await axios
        .get(`${BACKEND_URL}/team?actorId=${actor.actor_id}`, headers)
        .then((response) => setTeamList(response.data.data))
        .catch((error) => {
          if (error.response.status === 404) {
            setTeamList(null);
            return;
          }
          if (error.response.status === 401) {
            setState({
              ...state,
              redirect: true,
            });
            return;
          }
          setState({
            ...state,
            error: error.response.data.message,
          });
        });
    };
    getTeamList();
  }, [getAccessTokenSilently, actor, setState, state]);

  if (error) return <p>{error.message}</p>;

  if (isLoading) return <Loading />;

  if (state.redirect) return <Navigate to="/landing" />;

  return (
    <div>
      {state.error && <Alert color="danger">{state.error}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('menu.myTeam')}</h3>
        </CardHeader>
        <CardBody>
          <ButtonGroup>
            <ReturnButton to={'/landing'} />
            {!teamList && (
              <NavLink className="btn btn-primary" to="new">
                {t('buttons.add')}
              </NavLink>
            )}
          </ButtonGroup>
          <FormGroup row>{!teamList && <Alert color="light">{t('tables.empty')}</Alert>}</FormGroup>

          {teamList && (
            <Table hover borderless responsive size="sm" striped className="text-center">
              <thead>
                <tr>
                  <th>{t('tables.name')}</th>
                  <th>{t('catalogue.level')}</th>
                  <th colSpan={2}>{t('tables.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {teamList.map((team) => (
                  <tr key={team.name}>
                    <td>{team.name}</td>
                    <td>
                      <span className={`badge ${LEVEL_COLORS[team.prize_level]}`}>
                        {t(LEVEL_NAMES[team.prize_level])}
                      </span>
                    </td>
                    <td>
                      <Button color="link" title="Ver jugadores" size="sm" onClick={() => navigate(`${team.team_id}`)}>
                        <FontAwesomeIcon icon={faPeopleGroup} className="faWhite" />
                      </Button>
                      <Button color="link" title="Invitar" size="sm" onClick={() => navigate(`${team.team_id}/invite`)}>
                        <FontAwesomeIcon icon={faUserPlus} className="faWhite" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default withAuthenticationRequired(TeamList, {
  onRedirecting: () => <Loading />,
});
